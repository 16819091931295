import React from 'react';

// Import the ChevronsDown icon from the 'react-feather' icon library
import { ChevronsDown } from 'react-feather';

// Import styles for the LoadMoreIcon component
import './LoadMoreIcon.scss';

/**
* Comp: Load More Icon
*
* @desc - The Load More Icon component displays a down arrow icon.
* @returns {JSX.Element} - The ChevronsDown component.
*/
const LoadMoreIcon = () => {
  return (
    // Render the icon using React Feather
    <ChevronsDown size={40} className="load-more-icon" />
  );
};
export default LoadMoreIcon; // Export the ArrowIcon component