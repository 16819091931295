/**
 * A configuration object containing the Vimeo access token. 
 * This token is required for making API requests to Vimeo.
 * The configuration object can be imported and used in other 
 * modules that need access to the Vimeo API.
 *
 * @type {Object}
 * @property {string} vimeoAccessToken - The Vimeo access token.
 */
const config = {
  vimeoAccessToken: '0c5419f63faed419e7e549cbc729ca4b',
};

// Export the configuration object as the default export
export default config;