import React from 'react';

// Import the VideoList component from the VideoList module
import VideoList from './components/VideoList/VideoList';

// Import global styles for the application
import './styles/global.scss';

/**
 * App
 * 
 * @desc The main component of the application, renders the VideoList component.
 * @returns {JSX.Element} JSX element representing the application.
 */
const App = () => {
  // Render the VideoList component
  return <VideoList />;
};

// Export the App component as the default export
export default App;