// Import axios module to make HTTP requests
import axios from 'axios';

/**
 * Asynchronously fetches a list of videos from a Vimeo album.
 *
 * @async
 * @param {string} access_token - The access token for Vimeo API.
 * @param {number} [limit=5] - The maximum number of videos to return.
 * @param {number} [page=1] - The page number to return.
 * @returns {Promise<Object[]>} The list of videos or an empty array if an error occurs.
 * @throws {Error} When there is an error making the GET request.
 */
export const fetchVimeoVideos = async (access_token, limit = 6, page = 1) => {
  try {
    // Make an HTTP GET request to Vimeo API using axios
    const response = await axios.get('https://api.vimeo.com/me/albums/10327855/videos', {
      // Set headers for the request
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
      // Set parameters for the request
      params: {
        per_page: limit,
        page: page,
        fields: 'name,description,uri',
        sort: 'default',
      },
    });

    // Return the data from the response
    return response.data;
  } catch (error) {
    // Log the error to the console if there's an issue with the request
    console.error(error);
    // Return an empty array if there's an error
    return [];
  }
};