import React from 'react';

/* ---------------------------------------------- */

/**
 * Handles the "Load More" functionality in a video app.
 * @param {function} loadMoreVideos - A function that loads more videos into the app.
 * @param {function} setIsLoadMoreLoading - A function that sets the "loading" state of the "Load More" button.
 * @returns {Promise<void>} - A promise that resolves when the videos have finished loading.
 */
export const handleLoadMore = async (loadMoreVideos, setIsLoadMoreLoading) => {
  // Set the "loading" state of the "Load More" button to true
  setIsLoadMoreLoading(true);
  // Wait for the "loadMoreVideos" function to finish loading more videos
  await loadMoreVideos();
  // Set the "loading" state of the "Load More" button to false after 1 second
  setTimeout(() => {
    setIsLoadMoreLoading(false);
  }, 1000);
};

/* ---------------------------------------------- */

/**
 * Formats a description string to display line breaks correctly.
 * @param {string} description - The description string to format.
 * @returns {React.ReactFragment[]} - An array of React fragments, each containing a line of text followed by a line break element.
 */
export const formatDescription = (description) => {
  return description.split('\n').map((item, key) => {
    return <React.Fragment key={key}>{item}<br/></React.Fragment>
  });
};

/* ---------------------------------------------- */
