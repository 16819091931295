import React from 'react';

// Import the Video component from the 'Video' directory
import Video from '../Video/Video';

/**
 * Comp: VideoDisplay
 * 
 * @desc - In this code, the VideoDisplay component takes in a prop called "videos", which is an array of video objects.
 * The component checks if "videos" is an array using Array.isArray(), and if it is, it maps through each video and 
 * returns a Video component with a unique "key" prop assigned to it. The Video component is imported from the "../Video/Video" 
 * file, and the video object is passed in as a prop along with the unique key.
 *
 * @param {Array} videos - Array of video objects to be displayed.
 * @returns {JSX.Element} - VideoDisplay component.
 */
const VideoDisplay = ({ videos }) => (
  Array.isArray(videos) && videos.map((video, index) => 
    <Video key={index} video={video} /> // Pass in the video object as a prop to the Video component and assign a unique 'key' prop to it
  )
);

export default VideoDisplay; // Export the VideoDisplay component
