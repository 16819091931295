// Import useState and useEffect hooks from React
import { useState, useEffect } from 'react';

// Import fetchVimeoVideos function from VimeoAPI service
import { fetchVimeoVideos } from '../services/VimeoAPI';

// Import configuration from config module
import config from '../config/config';

/**
 * A custom hook that manages the state and logic for fetching videos from the Vimeo API.
 * It uses the fetchVimeoVideos function to fetch videos and handle pagination. The hook
 * maintains the state for the list of videos, the current page, and the total number of videos.
 * It also provides a function, loadMoreVideos, to fetch more videos when needed.
 *
 * @returns {Object} An object containing the list of videos, the total number of videos, and a function to load more videos.
 */
const useVideos = () => {
  // Declare state variables for videos, page, and totalVideos
  const [videos, setVideos] = useState([]);
  const [page, setPage] = useState(1);
  const [totalVideos, setTotalVideos] = useState(null);

  /**
   * Asynchronously loads videos from the Vimeo API.
   *
   * @async
   * @param {number} page - The page number to load.
   */
  const loadVideos = async (page) => {
    const access_token = config.vimeoAccessToken;

    try {
      // Fetch videos from the Vimeo API using the provided access token and page number
      const data = await fetchVimeoVideos(access_token, 10, page);

      // Check if the returned data is an array
      if (Array.isArray(data.data)) {
        // Update the state variables based on the current page
        if (page === 1) {
          setVideos(data.data);
          setTotalVideos(data.total);
        } else {
          setVideos((prevVideos) => [...prevVideos, ...data.data]);
        }
      } else {
        console.error('Error: data from API is not an array');
        console.log('Received data:', data);
      }
    } catch (error) {
      console.error('Error fetching videos:', error);
    }
  };

  // Call the loadVideos function whenever the page state changes
  useEffect(() => {
    loadVideos(page);
  }, [page]);

  /**
   * Increments the page state and fetches more videos.
   */
  const loadMoreVideos = () => {
    setPage((prevPage) => prevPage + 1);
  };

  // Return the state variables and the loadMoreVideos function
  return { videos, totalVideos, loadMoreVideos };
};

// Export useVideos as the default export
export default useVideos;