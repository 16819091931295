import React from 'react';

// Import the stylesheet for the component
import './Loader.scss';

/**
 * Component: Loader
 * @desc - The Loader component displays a spinner to indicate that content is being loaded.
 * @returns {JSX.Element} - The Loader component.
 */
const Loader = () => {
  // Render the loader as a div with a child div that displays the spinner animation
  return (
    <div className="loader">
      <div className="spinner"></div>
    </div>
  );
};

// Export the Loader component to be used in other files
export default Loader;