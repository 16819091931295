import React from 'react';

// Import styles for the Video component
import './Video.scss';

// Import the formatDescription utility function from the 'utils/helpers' module
import { formatDescription } from '../../utils/helpers';

/**
 * Comp: Video
 * 
 * @desc - This is the Video component that displays a single video. It takes in a prop called "video",
 * which is an object containing information about the video. The video object has three properties:
 * name, uri, and description. These properties are used to display the video.
 *
 * @param {Object} video - Object containing information about the video to be displayed.
 * @param {string} video.name - Name of the video.
 * @param {string} video.uri - Vimeo video URL.
 * @param {string} video.description - Description of the video.
 * @returns {JSX.Element} - Video component.
 */
const Video = ({ video }) => {
  // Vérifier si l'objet video est bien défini
  if (!video) {
    return <p>Chargement de la vidéo...</p>;
  }

  // Déstructuration avec des valeurs par défaut pour éviter les erreurs
  const { name = "Vidéo sans titre", uri = "", description = "" } = video;

  // Vérifier si l'URI contient au moins 3 segments pour éviter les erreurs sur .split('/')
  const videoId = uri.split('/')[2] || "";

  return (
    <div className="video">
      {/* Affichage du titre */}
      <h2 className="video__title">{name}</h2>

      {/* Vérifier que l'ID vidéo est présent avant d'afficher l'iframe */}
      <div className="video__player">
        {videoId ? (
          <iframe
            src={`https://player.vimeo.com/video/${videoId}?portrait=false&title=false&byline=false&share=false&watchlater=false&like=false&color=999999&loop=1`}
            width="960"
            height="540"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title={name}
          ></iframe>
        ) : (
          <p>Vidéo non disponible</p>
        )}
      </div>

      {/* Vérifier que la description est présente avant d'appeler formatDescription */}
      <p className="video__description">
        {description ? formatDescription(description) : "Pas de description disponible"}
      </p>
    </div>
  );
};

export default Video; // Export du composant Video
