import React, { useState, useEffect } from 'react';

// Import custom hook useVideos from the 'hooks' directory
import useVideos from '../../hooks/useVideos';

// Import VideoDisplay component from 'VideoDisplay' directory
import VideoDisplay from '../VideoDisplay/VideoDisplay';

// Import Loader component from 'Loader' directory
import Loader from '../Loader/Loader';

// Import LoadMoreButton component from 'LoadMoreButton' directory
import LoadMoreButton from '../LoadMoreButton/LoadMoreButton';

// Import the handleLoadMore function from the 'utils/helpers' directory
import { handleLoadMore } from '../../utils/helpers';

// Import styles for the VideoList component
import './VideoList.scss';

/**
 * Comp: VideoList
 * 
 * @desc - VideoList component renders a list of videos fetched from the Vimeo API.
 * It uses the useVideos custom hook to fetch and manage the video data.
 * The component provides a loading state and a LoadMoreButton to fetch additional videos.
 * @returns {JSX.Element} - The VideoList component.
 * 
 */
const VideoList = () => {
  // Use the useVideos custom hook to manage video fetching and state
  const { videos, totalVideos, loadMoreVideos } = useVideos();

  // State for managing loading states
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false);

  // Set the initial loading state when the component mounts
  useEffect(() => {
    setIsLoading(true);
  }, []);

  // Update the loading state when videos are fetched
  useEffect(() => {
    if (videos.length > 0) {
      setIsLoading(false);
    }
  }, [videos]);

  // Use the imported handleLoadMore function to load more videos and manage the loading state
  const handleLoadMoreVideos = () => {
    handleLoadMore(loadMoreVideos, setIsLoadMoreLoading);
  };

  // Render the VideoList component with a list of videos, a loader, and a LoadMoreButton
  return (
    <div className="videos">
      {isLoading && <Loader />} {/* Show the loader when the component is fetching videos */}
      {!isLoading &&
         <VideoDisplay videos={videos} />} {/* Display the fetched videos in a VideoDisplay component*/}
      {totalVideos && videos.length < totalVideos && (
        // Show the LoadMoreButton when there are more videos to fetch
        <LoadMoreButton
          handleLoadMore={handleLoadMoreVideos}
          isLoadMoreLoading={isLoadMoreLoading}
        />
      )}
    </div>
  );
};

export default VideoList;