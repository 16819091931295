import React from 'react';

// Import the LoadMoreIcon component from the 'LoadMoreIcon' directory
import LoadMoreIcon from '../LoadMoreIcon/LoadMoreIcon';

// Import the Loader component from the 'Loader' directory
import Loader from '../Loader/Loader';

// Import styles for the LoadMoreButton component
import './LoadMoreButton.scss';

/**
 * Comp: LoadMoreButton
 *
 * @desc - Component that represents the "Load More" button with the ability to show a loader when it's loading.
 * @param {Function} handleLoadMore - Function to be executed when the button is clicked.
 * @param {Boolean} isLoadMoreLoading - Whether the button is currently loading or not.
 * @returns {JSX.Element} - LoadMoreButton component.
 */
const LoadMoreButton = ({ handleLoadMore, isLoadMoreLoading }) => (
  <button
    className="load-more-button" // CSS class to be applied to the button
    onClick={handleLoadMore} // Function to be executed when the button is clicked
    disabled={isLoadMoreLoading} // Disable the button if it's currently loading
  >
    {isLoadMoreLoading ? <Loader /> : <LoadMoreIcon />} {/* Show the loader if it's currently loading, otherwise show the load more icon */}
  </button>
);

export default LoadMoreButton; // Export the LoadMoreButton component
